import React, {Component} from 'react'
import {Alert, Card, Col, Container, Row} from "react-bootstrap";
import axios from 'axios';

class Status extends Component {

    state = {
        pages: [{
            title:  "Chrome Extension",
            url: "https://extension-backend.trylavender.com/health"
        },{
            title:  "Coaching Dashboard",
            url: "https://dashboard.lavender.ai/sign-in"
        },{
            title:  "Dataflow APIs",
            url: "https://tasks.trylavender.com/health"
        },{
            title:  "Landing Page",
            url: "https://lavender.ai"
        }]
    }

    checkStatuses = () =>{
        let pages = this.state.pages;
        for(let k in pages){
            let index = k;
            axios( {url:"https://tasks.trylavender.com/health-2",params:{url:this.state.pages[index].url},method:"get"}).then((res)=>{
                pages[index].status = res.status
                this.setState({pages:pages})
            }).catch((res)=>{
                pages[index].status = res.status
                this.setState({pages:pages})
            })
        }
    }

    componentDidMount() {
        this.checkStatuses();
    }

    constructor(props) {
        super(props);
        this.checkStatuses = this.checkStatuses.bind(this)
    }

    render() {
        let allOperational = true;
        for(let page of this.state.pages){
            if(page.status && page.status !== 200){
                allOperational = false;
            }
        }
        return <Container>
            <Row>
                <Col md={8} className={"m-auto"}>
                    {/*TODO: if all is valid*/}
                    {allOperational ?
                        <Alert variant="success" className={"mt-4"}>
                            <Alert.Heading>All Systems Operational</Alert.Heading>
                            <p>If you are experiencing problems, please message support at the bottom left of the
                                landing page: <a href={"https://www.lavender.ai/"}>lavender.ai</a></p>
                        </Alert> : <Alert variant="danger" className={"mt-4"}>
                            <Alert.Heading>Some Systems Are Unavailable</Alert.Heading>
                            <p>Sorry for the interruption. We are working on it! <br/><br/>If you are experiencing problems, please message support at the bottom left of the
                                landing page: <a href={"https://www.lavender.ai/"}>lavender.ai</a></p>
                        </Alert>
                    }
                    {this.state.pages.map((val)=>{
                      return  <StatusRow key={val.title} title={val.title} url={val.url} status={val.status}/>
                    })}
                    <h3 className={"mt-4 pt-4"}>Past Incidents</h3>
                    <p className={"text-center mt-4 pt-4"}>No incidents in the last 90 days</p>
                </Col>
            </Row>

        </Container>
    }
}

class StatusRow extends Component {
    render() {
        return <Card className={"mt-3"}>
            <Card.Body>
                <Row>
                    <Col>
                        {this.props.title}
                    </Col>
                    {this.props.status === 200 ? <Col style={{flex:0}} className={"text-success"}>
                        {/*text-danger, text-warning*/}
                        Operational
                    </Col>:""}
                    {this.props.status && this.props.status !== 200 ? <Col style={{flex:0}} className={"text-danger"}>
                        {/*text-danger, text-warning*/}
                        Unavailable
                    </Col>:""}
                </Row>
            </Card.Body>
        </Card>
    }
}

export default Status