import React, {Component} from 'react'
import {Col, Container, Row} from "react-bootstrap";

class ReleaseNotes extends Component {
    render() {
        return <Container>
                <Row>
                    <Col md={8} className={"m-auto"}>
                        <h2 className={"mt-4 mb-4 pt-4"}>Release Notes:</h2>
                        <Row className={"mt-4"}>
                            <Row className={"mt-5 pt-4"}>
                                <Col><h3>Lavender 2.3.21</h3></Col>
                                <Col style={{textAlign:"right"}}><h5>5/22/23</h5></Col>
                            </Row>
                            <Row>
                                <h4 className={"mt-4"}>New Features</h4>
                                <ul style={{marginLeft: "2rem"}}>
                                    <li className={"mt-2"}>Alpha Groove extension integration released.</li>
                                    <li className={"mt-2"}>Alpha Apollo extension integration released.</li>
                                </ul>
                                <h4 className={"mt-2"}>Improvements</h4>
                                <ul style={{marginLeft: "2rem"}}>
                                    <li className={"mt-2"}>Salesloft API ingestion realtime.</li>
                                    <li className={"mt-2"}>Outreach API ingestion realtime.</li>
                                </ul>
                                <h4 className={"mt-2"}>Patches</h4>
                                <ul style={{marginLeft:"2rem"}}>
                                    <li className={"mt-2"}>Resolved issue with Profile Lookup not showing Tweets.</li>
                                    <li className={"mt-2"}>Resolved issue with billing updating to wrong subscription tier.</li>
                                </ul>
                            </Row>
                            <hr className={"mt-4"}/>
                        </Row>
                        <Row className={"mt-4"}>
                            <Row className={"mt-5 pt-4"}>
                                <Col><h3>Lavender 2.3.21</h3></Col>
                                <Col style={{textAlign:"right"}}><h5>5/7/23</h5></Col>
                            </Row>
                            <Row>
                                <h4 className={"mt-4"}>New Features</h4>
                                <ul style={{marginLeft: "2rem"}}>
                                    <li className={"mt-2"}>Added P.S. to our email analytics.</li>
                                    <li className={"mt-2"}>Added percents/multiples/prices to our email analytics.</li>
                                    <li className={"mt-2"}>Released an Enterprise Chrome Extension with stricter scopes.</li>
                                    <li className={"mt-2"}>Invite/referral/promo code system.</li>
                                    <li className={"mt-2"}>Full inbox API integration to Gmail.</li>
                                    <li className={"mt-2"}>Alpha Outlook add-in release.</li>
                                    <li className={"mt-2"}>Public Hubspot extension integration released.</li>
                                </ul>
                                <h4 className={"mt-2"}>Improvements</h4>
                                <ul style={{marginLeft: "2rem"}}>
                                    <li className={"mt-2"}>New on-boarding slides, combined slides and halved total number.</li>
                                    <li className={"mt-2"}>Invite to team slide to on-boarding for Teams subscription admins.</li>
                                    <li className={"mt-2"}>Improvements to the on-boarding slides on mobile devices. </li>
                                </ul>
                                <h4 className={"mt-2"}>Patches</h4>
                                <ul style={{marginLeft:"2rem"}}>
                                    <li className={"mt-2"}>Fixed logging out logged out all accounts.</li>
                                </ul>
                            </Row>
                            <hr className={"mt-4"}/>
                        </Row>
                        <Row className={"mt-4"}>
                            <Row className={"mt-5 pt-4"}>
                                <Col><h3>Lavender 2.3.20</h3></Col>
                                <Col style={{textAlign:"right"}}><h5>4/23/23</h5></Col>
                            </Row>
                            <Row>
                                <h4 className={"mt-4"}>New Features</h4>
                                <ul style={{marginLeft:"2rem"}}>
                                    <li className={"mt-2"}>Self-service annual billing for Teams & Pro plans.</li>
                                    <li className={"mt-2"}>Automated team trials.</li>
                                    <li className={"mt-2"}>Invite/referral/promo code system.</li>
                                    <li className={"mt-2"}>Option to ignore emails from analytics</li>
                                </ul>
                                <h4 className={"mt-2"}>Improvements</h4>
                                <ul style={{marginLeft:"2rem"}}>
                                    <li className={"mt-2"}>Button on the bottom of the thread to quickly use Start My Email for replies (Gmail only).</li>
                                </ul>
                                <h4 className={"mt-2"}>Patches</h4>
                                <ul style={{marginLeft:"2rem"}}>
                                    <li className={"mt-2"}>Deactivated the email analysis for non-english emails..</li>
                                    <li className={"mt-2"}>Fixes to Team subscription billing the correct amount.</li>
                                </ul>
                            </Row>
                            <hr className={"mt-4"}/>
                        </Row>
                        <Row className={"mt-4"}>
                            <Row className={"mt-5 pt-4"}>
                                <Col><h3>Lavender 2.3.19</h3></Col>
                                <Col style={{textAlign:"right"}}><h5>3/3/23</h5></Col>
                            </Row>
                            <Row>
                                <h4 className={"mt-4"}>New Features</h4>
                                <ul style={{marginLeft:"2rem"}}>
                                    <li className={"mt-2"}>Opt-out of analytics setting.</li>
                                    <li className={"mt-2"}>New version of Personalization Feed. It is in the full personalization assistant instead of email verification modal. (Deployed with rolling updates)</li>
                                </ul>
                                <h4 className={"mt-2"}>Improvements</h4>
                                <ul style={{marginLeft:"2rem"}}>
                                    <li className={"mt-2"}>Stricter security headers.</li>
                                    <li className={"mt-2"}>"Draft A.I. Reply" button styling & reply all by default.</li>
                                    <li className={"mt-2"}>Remove "Start My Email" minimum length.</li>
                                </ul>
                                <h4 className={"mt-2"}>Patches</h4>
                                <ul style={{marginLeft:"2rem"}}>
                                    <li className={"mt-2"}>Profile lookup in thread.</li>
                                    <li className={"mt-2"}>Personality data showing empty page.</li>
                                    <li className={"mt-2"}>Outreach email assistant bobbing up and down on page.</li>
                                </ul>
                            </Row>
                            <hr className={"mt-4"}/>
                        </Row>
                        <Row className={"mt-4"}>
                            <Row className={"mt-5 pt-4"}>
                                <Col><h3>Lavender 2.3.18</h3></Col>
                                <Col style={{textAlign:"right"}}><h5>2/25/23</h5></Col>
                            </Row>
                            <Row>
                                <h4 className={"mt-4"}>New Features</h4>
                                <ul style={{marginLeft:"2rem"}}>
                                    <li className={"mt-2"}>Draft A.I Reply Button at the bottom of reply thread. </li>
                                </ul>
                                <h4 className={"mt-2"}>Improvements</h4>
                                <ul style={{marginLeft:"2rem"}}>
                                    <li className={"mt-2"}>Lavender's analysis disabled for non-english emails.</li>
                                    <li className={"mt-2"}>Includes P.S. added to intelligence pipeline.</li>
                                </ul>
                                <h4 className={"mt-2"}>Patches</h4>
                                <ul style={{marginLeft:"2rem"}}>
                                    <li className={"mt-2"}>Various DAST/SAST vulnerabilities remediated.</li>
                                    <li className={"mt-2"}>Various container scan vulnerabilities remediated</li>
                                    <li className={"mt-2"}>Fixed extension loading in Outlook darkmode</li>
                                </ul>
                            </Row>
                            <hr className={"mt-4"}/>
                        </Row>
                        <Row className={"mt-4"}>
                            <Row className={"mt-5 pt-4"}>
                                <Col><h3>Lavender 2.3.17</h3></Col>
                                <Col style={{textAlign:"right"}}><h5>1/20/23</h5></Col>
                            </Row>
                            <Row>
                                <h4 className={"mt-4"}>New Features</h4>
                                <ul style={{marginLeft:"2rem"}}>
                                    <li className={"mt-2"}>Turned off "Progressive Onboarding".</li>
                                    <li className={"mt-2"}>New full onboarding on first time install.</li>
                                    <li className={"mt-2"}>Onboarding checklist in dashboard.</li>
                                    <li className={"mt-2"}>Save profile information in onboarding.</li>
                                    <li className={"mt-2"}>Referral system.</li>
                                </ul>
                                <h4 className={"mt-2"}>Improvements</h4>
                                <ul style={{marginLeft:"2rem"}}>
                                    <li className={"mt-2"}>Reorganized settings pages.</li>
                                </ul>
                                <h4 className={"mt-2"}>Patches</h4>
                                <ul style={{marginLeft:"2rem"}}>
                                    <li className={"mt-2"}>Fixed Salesforce BCC in Outlook crashing page.</li>
                                </ul>
                            </Row>
                            <hr className={"mt-4"}/>
                        </Row>
                        <Row className={"mt-4"}>
                            <Row className={"mt-5 pt-4"}>
                                <Col><h3>Lavender 2.3.17</h3></Col>
                                <Col style={{textAlign:"right"}}><h5>11/15/22</h5></Col>
                            </Row>
                            <Row>
                                <h4 className={"mt-4"}>New Features</h4>
                                <ul style={{marginLeft:"2rem"}}>
                                    <li className={"mt-2"}>Launched status and release updates page.</li>
                                    <li className={"mt-2"}>Launched Dashboard Feed. This page surfaces important and actionable insights to a scrollable page.</li>
                                    <li className={"mt-2"}>Launched Personalization Feed. This extension feature surfaces insight from the Personalization Assistant. This includes new & events, competitors, compliments, and hiring updates. Clicking the option generates an AI introduction sentence.</li>
                                </ul>
                                <h4 className={"mt-2"}>Improvements</h4>
                                <ul style={{marginLeft:"2rem"}}>
                                    <li className={"mt-2"}>Lavender Anywhere icon remembers position when dragged. If position is passed the screen, the position will be right above the bottom of the page.</li>
                                    <li className={"mt-2"}>Profile lookup performance improvements.</li>
                                    <li className={"mt-2"}>More restrictive scope for Outlook calendar.</li>
                                    <li className={"mt-2"}>Reduced number of slides in initial Progressive Onboarding.</li>
                                    <li className={"mt-2"}>Always ask to start tutorials in Progressive Onboarding.</li>
                                    <li className={"mt-2"}>Progressive Onboarding tutorials always able to exit.</li>
                                </ul>
                                <h4 className={"mt-2"}>Patches</h4>
                                <ul style={{marginLeft:"2rem"}}>
                                    <li className={"mt-2"}>Email assistant placement. Fixes default position of the vertical and horizontal email assistant.</li>
                                    <li className={"mt-2"}>Insights page shows correct KPI. Subject fields were showing effect on reply rate instead of open rate.</li>
                                    <li className={"mt-2"}>Clicking insert in Start My Email, didn't insert text correctly in Outlook.</li>
                                    <li className={"mt-2"}>Email summarizer was summarizing entire thread.</li>
                                    <li className={"mt-2"}>Open Awareness was pulling pixel from thread.</li>
                                    <li className={"mt-2"}>Passing subjects from Read the Room correctly.</li>
                                    <li className={"mt-2"}>Sometimes Lavender's Spelling & Grammar deletes text when clicking insert.</li>
                                    <li className={"mt-2"}>When people edit signature, Lavender now analyzes the changed text.</li>
                                </ul>
                            </Row>
                            <hr className={"mt-4"}/>
                        </Row>
                    </Col>
                </Row>
            </Container>
    }
}

export default ReleaseNotes
