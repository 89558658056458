import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Container, Nav, Navbar} from "react-bootstrap";
import {
    BrowserRouter as Router,
    Routes,
    Route,
} from "react-router-dom";
import Status from "./status";
import ReleaseNotes from "./ReleaseNotes";
import Logo from "./logo.png";

function App() {
   return (
        <Router>
            <div>
                <Navbar bg="light" variant="light">
                    <Container>
                        <Navbar.Brand href="/"><img style={{height: "3rem"}} alt={"Company Logo"} src={Logo}/></Navbar.Brand>
                        <Nav>
                            <Nav.Link href="/">Status</Nav.Link>
                            <Nav.Link href="/release-notes">Release Updates</Nav.Link>
                        </Nav>
                    </Container>
                </Navbar>
                <Routes>
                    <Route exact path="/release-notes" element={<ReleaseNotes/>}/>
                    <Route exact path="/" element={<Status/>}/>
                </Routes>
            </div>
        </Router>
   )
}

export default App;
